import React, { Component } from 'react';
import '../assets/scss/camping.scss';
import Button from './button';


class Camping extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show) {
      visibleClass += " visibility";
    };

    const [,,,,,,,,header] = this.props.header;
    const {rel, targBlank, camping} = this.props.button;

    return (
      <section id="camping" className="container">
        <div className={visibleClass}>
          <div id="frame-camping" role="grid">
            <div className="col-1 devider hidden">
              <hr/>
              <div className="label">
              <h3>{header.camping}</h3>
              </div>
            </div>
            <div className="col-2 img pull-md"></div>
            <div className="col-2 text push-md">
            <ul>
              <li className="title">
                <h1>{header.camping}</h1>
              </li>
              <li className="list-title">
                <h3>Oct. 05, 2019:</h3>
              </li>
              <li><p>Troop 62080 went on their very first overnight camping trip.t was quite the adventure filled with great memories. Girls learned to set up the camp ground, clean up after dinner, wash dishes, and work together as a team. We spent several hours infront of the fire cooking smores, telling scary stories, singing songs, and our Girl Scout Traditions (friendship circle).<br/><br/>It was a cold night! We had plenty of frozen feet. Temperature dropped down to the low 40s, leading us into an early wake-up call to pick ourselves up and run. Despite that, we had a wonderful time that will never be forgotten.<br/><br/>Can't wait for next time...</p></li>
              <li className="button">
              <a className={camping.nameClass}
                 rel={rel}
                 href={camping.href}
                 target={targBlank}
                 title={camping.title}
                 alt={camping.alt}>
              <Button text="GS Camping Options" />
              </a>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Camping;
