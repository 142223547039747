import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/main.scss';
import Errow from '../images/errow.svg';

const boxes = {
  firstBox: {
      date: "Earning Badges",
      time: "April - June",
      location: "HOME",
      boldInfo: "Girl Scout Junior Badges",
      info: "Girls have the opportunity to earn the followig badges while at home.",
      link: "Badges",
      href: "/badges",
      snack: "None"
    },
  secondBox: {
    date: "Zoom Meetings",
    time: "3:00 - 4:00pm",
    info: "Girl Scout Troop 62080",
    location: "ZOOM",
    description: "Please have your Junior Log On in her uniform to enjoy our Troop's virtual meeting.",
    link: "Zoom Log On",
    meeting: "Meeting ID: 403 289 9470",
    password: "Password: 401777",
    href: "https://us04web.zoom.us/s/4032899470?pwd=WWFsNllyYW4yTGJtajQ3R09URWZyZz09"
  }
};
const attrs = {
  rel: "noopener noreferrer",
  targBlank: "_blank",
  targSelf: "_self"
};
const icons = {
  locationIcon: {
    href: "https://www.google.com/maps/place/Nantucket,+MA/@41.3161773,-70.4216866,10z/data=!3m1!4b1!4m5!3m4!1s0x89fadc7bbbde6a6b:0x33a391f9049fc84d!8m2!3d41.2834704!4d-70.099451",
    title: "Google Maps",
    alt: "Map Link",
  },
  questionIcon: {
    href:"https://us04web.zoom.us/s/4032899470?pwd=WWFsNllyYW4yTGJtajQ3R09URWZyZz09",
    title: "Zoom Link",
    alt: "FAQ",
  }
};
const buttons = {
  firstBox: {
    href: "/schedule",
    title: "View Schedule",
    alt: "View Schedule"
  },
  secondBox: {
    href: "https://us04web.zoom.us/s/4032899470?pwd=WWFsNllyYW4yTGJtajQ3R09URWZyZz09",
    title: "Zoom Log On",
    alt: "Zoom Log On"
  }
}
const badges = ["Jewelry Making", "Drawing Badge", "Simple Meals Badge", "Scribe Badge"]

class Main extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show){
      visibleClass += " visibility";
    }

    return (
      <main role="main">
        <div id="home" className={visibleClass}>
          <div className="hidden-img"></div>
          <div className="hidden-col">
            <h1>Juniors of Nantucket</h1>
            <p>Girl Scout Troop 62080</p>
          </div>
          <div id="frame-1" role="grid">
            <div className="col-1 top-col">
              <ul className="box-1">
                <li className="date">
                  <h2>{boxes.firstBox.date}</h2>
                  <a className="locationIcon"
                     rel={attrs.rel}
                     href={icons.locationIcon.href}
                     target={attrs.targBlank}
                     title={icons.locationIcon.title}
                     alt={icons.locationIcon.alt}>
                    <svg className="location-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4d3219"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                  </a>
                </li>
                <li className="time">
                  <p><b>{boxes.firstBox.time}</b> @ {boxes.firstBox.location}</p>
                </li>
                <li className="info">
                  <p><b>{boxes.firstBox.boldInfo}</b><br/> {boxes.firstBox.info}</p>
                </li>
                <li>
                  <ul>
                    {badges.map( (badge) => {
                      return (
                        <li className="badgeList" key={badge}><p>• {badge}</p></li>
                      )
                    })}
                  </ul>
                </li>
                <li className="info-link">
                <Link to={boxes.firstBox.href}
                      className="link"
                      title={boxes.firstBox.link}
                      alt={boxes.firstBox.link}>
                {boxes.firstBox.link}
                </Link>
                </li>
                <li className="snk-duty">
                  <h4>Snack Duty: </h4>
                  <p>{boxes.firstBox.snack}</p>
                </li>
                <li className="button">
                  <Link to={buttons.firstBox.href}
                        className="sched-btn"
                        title={buttons.firstBox.title}
                        alt={buttons.firstBox.alt}>
                  View Schedule
                  <img src={Errow} className="errow" alt="Errow"/>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-1 bottom-col">
              <ul className="box-2">
                <li className="date">
                  <h2>{boxes.secondBox.date}</h2>
                  <a className="questionIcon"
                     rel={attrs.rel}
                     href={icons.questionIcon.href}
                     target={attrs.targBlank}
                     title={icons.questionIcon.title}
                     alt={icons.questionIcon.alt}>
                     <svg xmlns="http://www.w3.org/2000/svg" className="faq-icon" width="24" height="24" viewBox="0 0 24 24" fill="#4d3219"><path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm0 15c-.565 0-1.024-.459-1.024-1.025 0-.565.459-1.024 1.024-1.024.566 0 1.024.459 1.024 1.024 0 .566-.458 1.025-1.024 1.025zm1.606-4.858c-.74.799-.775 1.241-.766 1.785h-1.643c-.006-1.208.016-1.742 1.173-2.842.469-.446.84-.799.788-1.493-.047-.66-.599-1.004-1.117-1.004-.581 0-1.261.432-1.261 1.649h-1.646c0-1.966 1.155-3.237 2.941-3.237.849 0 1.592.278 2.09.783.468.473.709 1.125.7 1.883-.013 1.134-.704 1.878-1.259 2.476z"/></svg>
                  </a>
                </li>
                <li className="time">
                  <p><b>{boxes.secondBox.time}</b> @ {boxes.secondBox.location}</p>
                </li>
                <li className="info">
                  <h4>{boxes.secondBox.info}</h4><br/>
                </li>
                <li className="description">
                  <p>{boxes.secondBox.description}<br/></p>
                </li>
                <li className="info">
                  <p><br/><b>{boxes.secondBox.meeting}</b><br/></p>
                </li>
                <li className="info">
                  <p><b>{boxes.secondBox.password}</b><br/></p>
                </li>
                <li className="info-link">
                  <a href={boxes.secondBox.href}
                        className="link"
                        title={boxes.secondBox.title}
                        alt={boxes.secondBox.alt}
                        target={attrs.targBlank}>
                  {boxes.secondBox.link}
                  </a>
                </li>
                <li className="button">
                  <a href={buttons.secondBox.href}
                        className="cookie-btn"
                        title={buttons.secondBox.title}
                        alt={buttons.secondBox.alt}
                        target={attrs.targBlank}>
                  zoom
                  <img src={Errow} className="errow" alt="Errow"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Main;
