import React, { Component } from 'react';
import {HashLink as Link}  from 'react-router-hash-link';
import '../assets/scss/cookies.scss';
import badge from '../images/cookieCEObadge.jpg';
import pin from '../images/cookieEntrepPin.jpg';
import Button from './button';

const links = {
  digital: {
    nameClass: "digital-button",
    href: "https://www.girlscouts.org/en/cookies/just-for-cookie-sellers/my-digital-cookie/digital-cookie-app.html",
    alt: "Digital Cookies",
    title: "Digital Cookies"
  },
  order: {
    nameClass: "order-button",
    href: "https://issuu.com/gsema/docs/2019-20_gsema_cookie_book_final",
    alt: "Order-Taking Sales",
    title: "Order-Taking Sales"
  },
  booth: {
    nameClass: "booth-button",
    href: "https://www.girlscouts.org/content/dam/girlscouts-gsusa/forms-and-documents/cookie/Cookie%20Booth%20Essentials%20Flyer.pdf",
    alt: "Booth Sales",
    title: "Booth Sales"
  },
  proceeds: {
    nameClass: "proceeds-link",
    href: "https://www.girlscouts.org/content/dam/girlscouts-gsusa/forms-and-documents/cookie/Resources/2018_MarComm_CookieInfographic_Flyer_5x7(1).pdf",
    alt: "Proceeds",
    title: "Proceeds"
  },
  skills: {
    nameClass: "skills-link",
    href: "https://www.girlscouts.org/program/gs_cookies/pdf/2012_5_skills_shaping_your_girls_future.pdf",
    alt: "Skills",
    title: "Skills"
  }
};
const awards = {
  badge: {
    nameClass: "CEO-badge",
    img: <img src={badge} className="badge" alt="cookie badge" />,
    href: "https://www.girlscoutshop.com/JUNIOR-COOKIE-CEO-BADGE",
    text: "Cookie CEO Badge"
  },
  pin: {
    nameClass: "Entrep-pin",
    img: <img src={pin} className="badge" alt="cookie pin" />,
    href: "https://www.girlscouts.org/content/dam/girlscouts-gsusa/forms-and-documents/cookie/cef-req/GSUSA_CEF_Requirements-Junior.pdf",
    text: "Cookie Entrepreneur Pin"
  }
}

class Cookies extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show) {
      visibleClass += " visibility";
    };

    const [,,,,,,,,,header] = this.props.header;
    const {rel, targBlank, cookies} = this.props.button;
    const {digital, order, booth, proceeds, skills} = links;
    const {badge, pin} = awards;

    return (
      <section id="cookies" className="container">
        <div className={visibleClass}>
          <div id="frame-cookies" role="grid">
            <div className="col-1 devider hidden">
              <hr/>
              <div className="label">
              <h3>{header.cookies}</h3>
              </div>
            </div>
            <div className="col-2 text">
            <ul>
              <li className="title">
                <h1>{header.cookies}</h1>
              </li>
              <li className="cookie-img">
              </li>
              <li className="intro">
                <p><b>LOGIN:</b> <a href="https://www.abcsmartcookies.com/" target={targBlank}>ABC Smart Cookies</a></p>
                <p>Did you know the Girl Scout Cookie Program is the largest girl-led entrepreneurial program in the world? It’s true—and it’s pretty amazing!
                </p>
              </li>
              <li className="list-title">
                <h3>How Girls Scouts Benefit:</h3>
              </li>
              <li  className="info">
              <p><b>1-</b> They learn useful life  <a className={skills.nameClass}
                 href={skills.href}
                 target={targBlank}
                 title={skills.title}
                 alt={skills.alt}>skills</a>, gain a ton of confidence, and discover the leader inside.</p>
              <p><b>2-</b> Girls can earn their <Link to="#frame-badge-pin">Junior Cookie CEO badge</Link> and/or the <Link to="#frame-badge-pin">Cookie Entrepreneuur pin.</Link></p>
              <p><b>3-</b> Troops earn $0.90 per package of cookies sold.</p>
              <p><b>4-</b> All <a className={proceeds.nameClass}
                 href={proceeds.href}
                 target={targBlank}
                 title={proceeds.title}
                 alt={proceeds.alt}>proceeds
              </a> from Girl Scout Cookies® <b>stay local</b>!</p>
              </li>
            </ul>
            </div>
            <div className="col-2 img">
              <ul>
                <li className="button">
                <a className={cookies.nameClass}
                   href={cookies.href}
                   target={targBlank}
                   title={cookies.title}
                   alt={cookies.alt} >
                <Button text="Meet The Cookies" />
                </a>
                </li>
                <li className="cookie-boxes"></li>
              </ul>
            </div>
          </div>
          <div id="frame-3" role="grid">
          <div className="col-1"><h3 className="list-title">Ways to Sell Cookies:</h3></div>
            <div className="col-3">
              <ul>
                <li><h4>1- Digital Cookie Sale</h4></li>
                <li><p><b>Opens Nov. 21, 2019</b></p></li>
                <li>
                <p>Girls go on line, create goals, set up mini websites, & send ecards to family & friends. Customers can shop & pay online, & the cookies will be shipped directly to the customer.</p>
                </li>
                <li class="button">
                <a className={digital.nameClass}
                   href={digital.href}
                   target={targBlank}
                   title={digital.title}
                   alt={digital.alt}
                >
                <Button text="Digital Cookies" />
                </a>
                </li>
              </ul>
            </div>
            <div className="col-3">
            <ul>
              <li><h4>2- Order-Taking Sale</h4></li>
              <li><p><b>Opens Dec. 03, 2019</b></p></li>
              <li>
              <p>Girls sell cookies in person using the cookie order card, which is then turned into the Troop Cookie Coordinator. Girls <b>MUST</b> collect customer payment at time of order.</p>
              </li>
              <li className="button">
              <a className={order.nameClass}
                 href={order.href}
                 target={targBlank}
                 title={order.title}
                 alt={order.alt}
              >
              <Button text="Reference Guide" />
              </a>
              </li>
            </ul>
            </div>
            <div className="col-3">
            <ul>
              <li><h4>3- Booth Sale</h4></li>
              <li><p><b>Opens Dec. 03, 2019</b></p></li>
              <li>
              <p>Troops pick up cookies at a cookie cupboard, then sell them directly to customers. Cookies can be sold door-to-door; or at booth sales within the community.</p>
              </li>
              <li className="button">
              <a className={booth.nameClass}
                 href={booth.href}
                 target={targBlank}
                 title={booth.title}
                 alt={booth.alt}
              >
              <Button text="Booth Sales" />
              </a>
              </li>
            </ul>
            </div>
          </div>
          <div id="frame-badge-pin" role="grid">
            <div className="col-1 text">
              <h3 className="list-title">Earn Your Cookie Pin & Badge:</h3>
              <p className="info">Girls can earn the <a className={badge.nameClass} href={badge.href} target={targBlank} title={badge.text} alt={badge.text}>Cookie CEO Badge</a> and <a className={pin.nameClass} href={pin.href} target={targBlank} title={pin.text} alt={pin.text}>Cookie Entrepreneur Family Pin</a> for every year they participate in the Girl Scout Cookie Program.</p>
            </div>
            <div className="col-3">
              <a className={badge.nameClass} href={badge.href} target={targBlank} title={badge.text} alt={badge.text}>
              {badge.img}
              </a>
            </div>
            <div className="col-3">
              <a className={pin.nameClass} href={pin.href} target={targBlank} title={pin.text} alt={pin.text}>
              {pin.img}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cookies;
