import React, { Component } from 'react';
import {HashLink as Link}  from 'react-router-hash-link';
import '../assets/scss/pins.scss';
import Button from './button';
import awardsImage from '../images/awardPins.jpg';
import pinsImage from '../images/pins.jpg';
import aideImage from '../images/juniorAideAward.jpg'

const data = {
  mainText: "Girl Scout pins & awards are earned for their accomplishments in skill-building activities; or any set of requirements met.",
  awardsTitle: "Awards:",
  pinsTitle: "Pins:"
};
const award = {
  bronze: {
    bold: "Bronze Award Pin - ",
    text: "The highest honor a Junior can achieve. Earning the Girl Scout Bronze Award involves the time to complete a Journey, and then a suggested minimum of 20 hours building your team, exploring your community, choosing your project, planning it, putting your plan in motion, and spreading the word about your project."
  },
  summit: {
    bold: "Summit Pin - ",
    text: "Girls earn this award by completing all three Journeys at the Junior level."
  },
  safety: {
    bold: "Safety Pin - ",
    text: "This is earned when Girl Scouts learn to stay safe during their Junior adventures. "
  },
  aide: {
    bold: "Junior Aide - ",
    text: "This award represents active assistance as a Junior Girl Scout, helping Brownie Girl Scouts bridge to Junior Girl Scouting. "
  }
};
const pin = {
  membership: {
    bold: "Membership Pin - ",
    text: "This pin shows that you are a Girl Scout Junior."
  },
  star: {
    bold: "Membership Stars - ",
    text: "Each star represents one year as a Girl Scout. The number of stars shows how many years you’ve been a member at each level."
  },
  cookie: {
    bold: "Cookie Pin - ",
    text: "The Cookie Entrepreneur Family Pin program engages the support of family members—whether immediate or extended—as they help Juniors to focus on their individual goals and skills in their first year of selling cookies as a Girl Scout Junior."
  }
};

class Pins extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show) {
      visibleClass += " visibility";
    };

    const [,,,,,,header] = this.props.header;
    const {rel, targBlank, targSelf, pins} = this.props.button;

    return (
      <section id="pins" className="container">
        <div className={visibleClass}>
          <div id="frame-2" role="grid">
            <div className="col-1 devider hidden">
              <hr/>
              <div className="label">
              <h3>{header.pins}</h3>
              </div>
            </div>
            <div className="col-2 text">
            <ul>
            <li className="title"><h1>{header.pins}</h1></li>
            <li><p>{data.mainText}</p></li>
            <li id="awards">
              <li className="list-title"><h3>{data.awardsTitle}</h3></li>
              <li className="pins-img"><img src={awardsImage} alt="Junior Award Pins"/></li>
              <li><p><b>{award.bronze.bold}</b>{award.bronze.text}</p></li>
              <li><p><b>{award.summit.bold}</b>{award.summit.text}</p></li>
              <li><p><b>{award.safety.bold}</b>{award.safety.text}</p></li>
              <li className="pins-img"><img src={aideImage} alt="Junior Award Pins"/></li>
              <li><p><b>{award.aide.bold}</b>{award.aide.text}</p></li>
            </li>
            <li className="button">
            <a className={pins.nameClass}
               rel={rel}
               href="https://www.girlscoutshop.com/search?keywords=junior%20pins"
               target={targBlank}
               title="Junior Pins"
               alt="Junior Pins">
            <Button text="Junior Award Pins" />
            </a>
            </li>
            <li id="pins">
              <li className="list-title"><h3>{data.pinsTitle}</h3></li>
              <li className="pins-img"><img src={pinsImage} alt="Junior Pins"/></li>
              <li><p><b>{pin.membership.bold}</b>{pin.membership.text}</p></li>
              <li><p><b>{pin.star.bold}</b>{pin.star.text}</p></li>
              <li><p><b>{pin.cookie.bold}</b>{pin.cookie.text}</p></li>
            </li>
            <li className="button">
            <Link to className={pins.nameClass}
               rel={rel}
               to={pins.href}
               target={targSelf}
               title={pins.title}
               alt={pins.alt}>
            <Button text="Pins on Uniform" />
            </Link>
            </li>
            </ul>
            </div>
            <div className="col-2 img"></div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pins;
