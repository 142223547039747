import React, { Component } from 'react';
import '../assets/scss/schedule.scss';
import Button from './button';

const headers = [{
  empty: null,
  date: "Date",
  location: "Location",
  time: "Time",
  snack: "Snack",
  desc: "Description",
}];

const obj = [{
  empty: null,
  day: "Tues - 09.17.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Anderson",
  desc: "Learn How To Camp",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 10.01.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Backus",
  desc: "Prepare for Camping",
  check: <p>&#9989;</p>
},
{
  day: "Sat - 10.05.19",
  location: "45 Polpis Road",
  time: "3:00pm - 8:00am",
  snack: "Troop",
  desc: "Overnight Camping",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 10.15.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Bedell",
  desc: "Making thank you card and flowers for Zona",
  check: <p>&#9989;</p>
},
{
  day: "Sat - 10.26.19",
  location: "Hindsdale Park",
  time: "9:00 - 10:30am",
  snack: "None",
  desc: "Planting Daffodils @ Old South Rd Playground",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 10.29.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Canty",
  desc: "Making Run For Robin posters",
  check: <p>&#9989;</p>
},
{
  day: "Sun - 11.03.19",
  location: "6 Sankaty Rd. Sconset",
  time: "9:15 - 10:30am",
  snack: "None",
  desc: "Run For Robin",
  check: <p>&#9989;</p>
},
{
  day: "Mon - 11.11.19",
  location: "The American Legion",
  time: "9:30 am",
  snack: "None",
  desc: "Veterans Day Parade",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 11.12.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Cox",
  desc: "Christmas Stroll Decorations",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 11.19.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Barrett",
  desc: "Christmas Stroll Decorations",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 12.03.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Buchman",
  desc: "Christmas Tree Decorations",
  check: <p>&#9989;</p>
},
{
  day: "Thurs - 12.05.19",
  location: "1 Centre Street",
  time: "4:45 pm",
  snack: "None",
  desc: "Decorate Christmas Stroll Tree",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 12.17.19",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Dutra",
  desc: "Cookie Goals",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 01.07.20",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Kuszpa",
  desc: "Cookie Goals Part 2",
  check: <p>&#9989;</p>
},
{
  day: "Jan. 22 or 23",
  location: "TBD",
  time: "TBD",
  snack: "Council",
  desc: "Community Service Project Night",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 01.28.20",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Libby",
  desc: "Thank you notes to our Troops",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 02.11.20",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "Bedell",
  desc: "Cookie Insights",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 02.18.20",
  location: "NHS Cafe",
  time: "4:00 - 5:00pm",
  snack: "McClean",
  desc: "Cookie distributions",
  check: <p>&#9989;</p>
},
{
  day: "Wed - 02.19.20",
  location: "NHS Cafe",
  time: "5:30 - 7:00pm",
  snack: "Cultural Dishes",
  desc: "World Thinking Day",
  check: <p>&#9989;</p>
},
{
  day: "Fri - 03.06.20",
  location: "Unitarian Church",
  time: "6:00 - 7:00pm",
  snack: "Council",
  desc: "Girl Scout Sunday",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 03.31.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Snell",
  desc: "Discuss Jewelry Making Badge",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 04.07.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Valero",
  desc: "Discuss Drawing Badge",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 04.14.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Anderson",
  desc: "Discuss Simple Meals Badge",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 04.28.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Backus",
  desc: "Discuss Scribe Badge",
  check: <p>&#9989;</p>
},
{
  day: "Tues  - 05.05.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Barrett",
  desc: "Discuss Cybersecurity Safeguards Badge",
  check: <p>&#9989;</p>
},
{
  day: "Tues - 05.19.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Buchman",
  desc: "Cancelled"
},
{
  day: "Fri - 05.22.20",
  location: "Prospect Hill Cemetery",
  time: "Cancelled",
  snack: "None",
  desc: "Flower Planting"
},
{
  day: "Mon - 05.25.20",
  location: "TBD",
  time: "Cancelled",
  snack: "None",
  desc: "Memorial Day Parade"
},
{
  day: "Tues  - 06.02.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Canty",
  desc: "Cancelled"
},
{
  day: "Tues - 06.16.20",
  location: "ZOOM",
  time: "3:00 - 4:00pm",
  snack: "Cox",
  desc: "Cancelled"
}];

class Calendar extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show){
      visibleClass += " visibility";
    }

    const [,,,header] = this.props.header;
    const {rel, targBlank, schedule} = this.props.button;

    const thead = headers.map((header, index) =>
      <tr>
        <td key={header.empty}>{header.empty}</td>
        <td className="date" key={header.date}>{header.date}</td>
        <td className="location" key={header.location}>{header.location}</td>
        <td className="time" key={header.time}>{header.time}</td>
        <td className="snack" key={header.snack}>{header.snack}</td>
        <td  className="hidden" key={header.desc}>{header.desc}</td>
      </tr>
    );
    const row = obj.map((data) =>
      <tr>
        <td className="empty" key={data.empty}>{data.empty}</td>
        <td key={data.day}><b>{data.day}</b></td>
        <td key={data.location}>{data.location}</td>
        <td key={data.time}>{data.time}</td>
        <td key={data.snack}>{data.snack}</td>
        <td className="hidden" key={data.desc}>{data.desc}</td>
        <td><p className="check">{data.check}</p></td>
      </tr>
    );

    return (
      <section id="schedule" className="container">
          <div className={visibleClass}>
          <div id="frame-1">
            <div className="col-1 title">
              <h1>{header.schedule}</h1>
            </div>
            <div className="col-1 devider hidden">
              <hr/>
              <div className="label">
              <h3>{header.schedule}</h3>
              </div>
            </div>
            <table>
              <thead>{thead}</thead>
              <tbody>{row}</tbody>
            </table>
            <div className="col-1 volunteer-btn">
              <a className={schedule.nameClass}
                 rel={rel} href={schedule.href}
                 target={targBlank}
                 title={schedule.title}
                 alt={schedule.alt}>
              <Button text="Be a Volunteer" />
              </a>
           </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Calendar;
