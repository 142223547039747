import React, { Component } from 'react';
import {HashLink as Link}  from 'react-router-hash-link';
import '../assets/scss/badges.scss';
import Button from './button';
import badge from '../images/currentBadge.jpg';
import camper from '../images/_camper.jpg';
import gardener from '../images/_gardener.jpg';
import runRobin from '../images/_runRobin.jpg';
import ceo from '../images/_cookieCEO.jpg';
import service from '../images/_srvsFromHeart.jpg';
import insights from '../images/_customerInsights.jpg';
import jewelry from '../images/_jewelMaking.jpg';
import drawing from '../images/_drawing.jpg';
import meals from '../images/_meals.jpg';
import scribe from '../images/_scribe.jpg';


const data = {
  title: "Junior Scribe Badge:",
  bold: "Explore what you can do with words - ",
  text: "and find out how you can encourage, entertain, and excite people with your writing.",
  link: "https://www.girlscoutshop.com/JUNIOR-SCRIBE-BADGE"
};
const badgeImgs = [{
  img: <img src={camper} className="badge" alt="camper badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-CAMPER-BADGE",
  text: "Camper"
},
{
  img: <img src={gardener} className="badge" alt="gardener badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-GARDENER-BADGE",
  text: "Gardener"
},
{
  img: <img src={runRobin} className="badge" alt="helping out badge" />,
  href: "https://www.girlscoutshop.com/search?keywords=helping%20out",
  text: "Helping Out"
},
{
  img: <img src={ceo} className="badge" alt="Cookie CEO badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-COOKIE-CEO-BADGE",
  text: "Cookie CEO"
},
{
  img: <img src={service} className="badge" alt="Service From the Heart badge" />,
  href: "https://www.girlscoutshop.com/search?keywords=helping%20out",
  text: "Service From the Heart"
},
{
  img: <img src={insights} className="badge" alt="Customer Insights badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-CUSTOMER-INSIGHTS-BADGE",
  text: "Customer Insights"
},
{
  img: <img src={jewelry} className="badge" alt="Jewelry Making badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-JEWELER-BADGE",
  text: "Jewelry Making"
},
{
  img: <img src={drawing} className="badge" alt="Drawing badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-DRAWING-BADGE",
  text: "Drawing"
},
{
  img: <img src={meals} className="badge" alt="Simple Meals badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-SIMPLE-MEALS-BADGE",
  text: "Simple Meals"
},
{
  img: <img src={scribe} className="badge" alt="Scribe badge" />,
  href: "https://www.girlscoutshop.com/JUNIOR-SCRIBE-BADGE",
  text: "Scribe"
}
];

class Badges extends Component {
  render () {
    let visibleClass = "max-container";
    if(this.props.show) {
      visibleClass += " visibility";
    };

    const [,,,,,header] = this.props.header;
    const {rel, targBlank, badges} = this.props.button;

    return (
      <section id="badges" className="container">
        <div className={visibleClass}>
          <div id="frame-2" role="grid">
            <div className="col-1 devider hidden">
              <hr/>
              <div className="label">
              <h3>{header.badges}</h3>
              </div>
            </div>
            <div className="col-2 text">
            <ul>
              <li className="title"><h1>{header.badges}</h1></li>
              <li className="badge-img"><a href={data.link} target={targBlank}><img src={badge} alt="Junior Badges Earned" /></a></li>
              <li className="sub-title"><h3>{data.title}</h3></li>
              <li className="text"><p><b>{data.bold}</b>{data.text}</p></li>
              <li className="text">
                <li>1. Start with a poem</li>
                <li>2. Create a short story</li>
                <li>3. Use words to share who you are</li>
                <li>4. Write an article</li>
                <li>5. Tell the world what you think</li>
              </li>
              <li className="link"><Link to="#other_badges_earned">Other Badges Earned</Link></li>
              <li className="button">
              <a className={badges.nameClass}
                 rel={rel}
                 href={badges.href}
                 target={targBlank}
                 title={badges.title}
                 alt={badges.alt}>
              <Button text="Junior Badges" />
              </a>
              </li>
            </ul>
            </div>
            <div className="col-2 img"></div>
            <div className="col-1" id="other_badges_earned">
              <hr/>
              <div className="label">
                <h3>Badges Earned</h3>
              </div>
            </div>
            {badgeImgs.map( (badge) => {
              return (
                <React.Fragment>
                  <div className="col-6">
                    <a className="badge_link" href={badge.href} target={targBlank} title={badge.text} alt={badge.text}>
                    {badge.img}
                    <p>{badge.text}</p>
                    </a>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Badges;
